.bibliography-item {
  padding: 1em 0;
  margin: 1em 0;
  border-top: 1px gray solid;
  display: flex;
  flex-direction: column;

  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 0.1em;
  }

  .bibliography-item-image {
    text-align: center;
    margin-bottom: 1em;
    img {
      border: 1px solid black;
    }
  }
}

@media screen and (min-width: 600px) {
  .bibliography-item {
    flex-direction: row;
    .bibliography-item-image {
      min-width: 200px;
      margin-right: 1em;
    }

    .bibliography-item-text {
      margin-left: 0;
    }
  }
}
